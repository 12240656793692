import React from "react";
import { Global } from "@emotion/react";
import {
  Box,
  Flex,
  Themed
} from "theme-ui";
import { Link } from "gatsby";

import MenuLinks from "./MenuLinks";
import FooterLinks from "./FooterLinks";
import useSiteMetadata from "../hooks/use-sitemetadata";
import PageIntro from "./PageIntro";

const PageLayout = ({ children }) => {
  const {
    title = "",
    menuLinks = [],
    footerLinks = [],
    basePath,
    intro
  } = useSiteMetadata();

  return (
    <Themed.root>
      <Global
        styles={{
          body: {
            margin: "0",
            textUnderlinePosition: "under"
          },
          "*": {
            boxSizing: "border-box"
          }
        }}
      />
      <Flex variant="styledLayout">
        <Flex variant="container">
          <Flex variant="header">
            <MenuLinks items={menuLinks} />
            <Themed.h2>
              <Themed.a as={Link} to={basePath}>
                {title}
              </Themed.a>
            </Themed.h2>
            <PageIntro>{intro}</PageIntro>
          </Flex>
          <Flex sx={{
            flexDirection: "column"
          }}
          >{children}</Flex>
          <Box variant="footer">
            <FooterLinks items={footerLinks} />
          </Box>
        </Flex>
      </Flex>
    </Themed.root>
  );
};

export default PageLayout;
