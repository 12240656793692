/** @jsx jsx */
import { jsx } from "@emotion/react";

import ThemedLink from "./ThemedLink";

const MenuLinks = ({ items }) => {
  return (
    <nav>
      {items &&
        items.length > 0 &&
        items.map(({ name, slug }) => (
          <ThemedLink key={name} to={slug}>
            {name}
          </ThemedLink>
        ))}
    </nav>
  );
};

export default MenuLinks;
