/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment } from "react";
import { Button, useColorMode } from "theme-ui";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import ThemedLink, { linkStyle } from "./ThemedLink";

const FooterLinks = ({ items }) => {
  const [colorMode, setColorMode] = useColorMode();
  return (
    <nav>
      {items &&
      items.length > 0 &&
      items.map(({ name, href }) => {
        const isExternal = /^http/.test(href);
        return (
          <Fragment key={href}>
            {isExternal && (
              <OutboundLink href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={theme => linkStyle({ theme })}
              >
                {name}
              </OutboundLink>
            )}
            {!isExternal && <ThemedLink to={href}>{name}</ThemedLink>}
          </Fragment>
        );
      })}

      <Button
        type="button"
        size="small"
        variant="primary"
        className="color-mode-btn"
        onClick={() => {
          setColorMode(colorMode === "default" ? "dark" : "default");
        }}
      >
        {colorMode === "default" ? "Dark" : "Light"} Theme
      </Button>
    </nav>
  );
};

export default FooterLinks;
