/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Themed } from "theme-ui";

const PageIntro = ({ children }) => {
  return (
    <Themed.p
      css={theme =>
        css`
          text-align: center;
          padding: ${theme.space[3]}px;
          margin: ${theme.space[2]}px auto 0;
        `
      }
    >
      {children}
    </Themed.p>
  );
};

export default PageIntro;
